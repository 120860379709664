import React, { useState } from "react";
import beograd from "../../assets/contact.png"
import './contact.css'
import { useTranslation } from 'react-i18next'
import emailjs from '@emailjs/browser'
import axios from "axios";

function Contact() {
    const [t, i18n] = useTranslation('global');

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const sendEmail = async (e) => {
        e.preventDefault();

        // EmailJs service id
        const serviceId = 'service_yesibye';
        const templateId = 'template_j73xun9';
        const publicKey = 'Q4Hba7DTP-1MCAkWA';
        // Set params normal
        // const templateParams = {
        //     from_name: name,
        //     from_email: email,
        //     to_name: "Web Site",
        //     message: message,
        // }

        // Set params axios
        const data = {
            service_id: serviceId,
            template_id: templateId,
            user_id: publicKey,
            template_params: {
                from_name: name,
                from_email: email,
                to_name: 'Web Site',
                message: message,
            }
        }

        try {
            const res = await axios.post("https://api.emailjs.com/api/v1.0/email/send", data);
            // console.log(res.data);
            if (res.data == 'OK') {
                alert("Message sent!");
            } else {
                alert("Sending failed!");
            }
            setName('');
            setEmail('');
            setMessage('');
        } catch (error) {
            console.log(error);
        }

        // Send email stari nacin normal
        // emailjs.send(serviceId, templateId, templateParams, publicKey)
        //     .then((response) => {
        //         console.log("Email sent successfully", response);
        //         setName('')
        //         setEmail('')
        //         setMessage('')
        //     })
        //     .catch((error) => {
        //         console.log("Error sending email:", error);
        //     })

    }


    return (
        <div id="contact" className="forma">
            <h1>{t("contact.title")}</h1>
            <div className="bgd"><img src={beograd} /></div>
            <div className="contact">
                <div className="company">
                    <h3>{t("contact.firma")}</h3>
                    <p>{t("contact.address")}</p>
                    <p>{t("contact.grad_drzava")}</p>
                    <p>{t("contact.phone")}</p>
                    <p>{t("contact.email")}</p>
                </div>
                <form className='cf' onSubmit={sendEmail}>
                    {/* <div className='half left cf'> */}
                    <input type='text' placeholder={t("contact.field_ime")} value={name} onChange={(e) => setName(e.target.value)} />
                    <input type='email' placeholder={t("contact.field_email")} value={email} onChange={(e) => setEmail(e.target.value)} />
                    {/* </div> */}
                    {/* <div className='half right cf'> */}
                    <textarea type='text' placeholder={t("contact.field_message")} value={message} onChange={(e) => setMessage(e.target.value)}></textarea>
                    {/* </div> */}
                    <input type='submit' value={t("contact.button_formSubmit")} id='input-submit' />
                </form>
            </div>
        </div>
    );
}

export default Contact