import React from 'react'
import './misija.css'
import misija from '../../assets/misija.png'
import { PiPillFill } from 'react-icons/pi'
import { useTranslation } from 'react-i18next'

function Misija() {
    const [t, i18n] = useTranslation('global');
    return (
        <>
            <div className='skewed'>
                <div className='text'>
                    {/* <small>Prva Linija</small> */}
                    <h1>{t("mission.our_mission")}</h1>
                    <p>
                        <PiPillFill /> {t("mission.paragraph_1")}<br />
                        <PiPillFill /> {t("mission.paragraph_2")}<br />
                        <PiPillFill /> {t("mission.paragraph_3")}      </p>
                    {/* <a href='#'>Saznajte više &rarr;</a> */}
                </div>
                <div className='image'>
                    <div>
                        <img src={misija} alt='O_nama' />
                    </div>
                </div>
            </div>

        </>
    )
}

export default Misija