import React from 'react'
import './about.css'
import o_nama from '../../assets/o_nama.png'
import Cert from '../../assets/ISO_9001_2015_sr.pdf'
import { PiPillFill } from 'react-icons/pi'
import { useTranslation } from 'react-i18next'
function About() {
    const [t, i18n] = useTranslation('global');
    return (
        <>
            <div className='skewed'>
                <div className='text'>
                    {/* <small>Prva Linija</small> */}
                    <h1 id='about'>{t("about.about")}</h1>
                    <p><PiPillFill /> {t("about.paragraph_1")}<br /><br />
                        <PiPillFill /> {t("about.paragraph_2")}<br /><br />
                        <PiPillFill /> {t("about.paragraph_3")}
                        <a className='btn' target='_blank' href={Cert}>ISO 9001:2015</a>
                    </p>
                </div>
                <div className='image'>
                    <div>
                        <img src={o_nama} alt='O_nama Milos' />
                    </div>
                </div>

            </div>

        </>
    )
}

export default About