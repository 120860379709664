import React from 'react'
import './cilj.css'
import vizija from '../../assets/vision.png'
import { PiPillFill } from 'react-icons/pi'
import { useTranslation } from 'react-i18next'
function Cilj() {
    const [t, i18n] = useTranslation('global');
    return (
        <>
            <div className='skewed-left'>
                <div className='image'>
                    <div>
                        <img src={vizija} alt='Vizija' />
                    </div>
                </div>
                <div className='text'>
                    {/* <small>Prva Linija</small> */}
                    <h1>{t("vision.our_vision")}</h1>
                    <p><PiPillFill /> {t("vision.paragraph_1")}<br />
                        <PiPillFill /> {t("vision.paragraph_2")}</p>

                    {/* <a href='#'>Saznajte više &rarr;</a> */}
                </div>
            </div>

        </>
    )
}

export default Cilj