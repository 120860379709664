import React, { useState, useRef, useEffect } from 'react'
import { FaBars, FaTwitter } from 'react-icons/fa'
import { links, social } from '../data'
import logo from '../assets/logo.svg'
import { useTranslation } from 'react-i18next'



const Navbar = () => {
  const [showLinks, setShowLinks] = useState(false)
  const linksContainerRef = useRef(null)
  const linksRef = useRef(null)
  const [t, i18n] = useTranslation('global');

  useEffect(() => {
    const linksHeight = linksRef.current.getBoundingClientRect().height
    if (showLinks) {
      linksContainerRef.current.style.height = `${linksHeight}px`
    } else {
      linksContainerRef.current.style.height = `0px`
    }
  }, [showLinks])
  return <nav>
    <div className='nav-center'>
      <div className='nav-header'>
        <img className='logo' src={logo} alt='logo' />
        <button className='nav-toggle' onClick={() => setShowLinks(!showLinks)}>
          <FaBars />
        </button>
      </div>

      <div className='links-container' ref={linksContainerRef}>
        <ul className='links' ref={linksRef}>
          <a href='#home'>{t("navbar.home")}</a>
          <a href='#about'>{t("navbar.about")}</a>
          <a href='#our_partners'>{t("navbar.our_partners")}</a>
          <a href='#contact'>{t("navbar.contact")}</a>
          {/* {links.map((link) => {
            const { id, url, text } = link;
            return (
              <li key={id}>
                <a href={url}>{text}</a>
              </li>
            )
          })} */}
        </ul>
      </div>
    </div>
  </nav>
}

export default Navbar
