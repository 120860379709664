import React from 'react'
import './index'
import Navbar from './components/Navbar'
import LanguageBar from './components/LanguageBar'
import Main from './containers/main/Main'
import About from './containers/about/About'
import Slika from './containers/Slika/Slika'
import Cilj from './containers/Cilj/Cilj'
import Misija from './containers/Misija/Misija'
import Contact from './containers/Contact/Contact'
import Footer from './containers/Footer/Footer'
import Partners from './containers/Partners/Partners'
import ScrollToTop from 'react-scroll-to-top'
import { ReactComponent as Up } from "./assets/up.svg";

function App() {
  return (
    <>
      <ScrollToTop smooth component={<Up />} style={{ width: "40px", height: "40px", padding: "8px", borderRadius: "50%", backgroundColor: 'lightblue', color: 'white' }} />
      <LanguageBar />
      <Navbar />
      <Main />
      <Slika />
      <About />
      <Cilj />
      <Misija />
      <Partners />
      <Contact />
      <Footer />

    </>
  )
}

export default App
