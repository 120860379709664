import React from 'react'
import './footer.css'

function Footer() {
    return (

        <div className='footer'>
            <div className='top_line'>
                <div className='line'></div>
            </div>
            {/* <p>+381 11 3962 403   •   info@beomedica.rs   •   www.beomedica.rs</p> */}
            <p>Copyright &copy; 2023. PichWise Creative All rights Reserved</p>
            <div className='empty'></div>
        </div>
    )
}

export default Footer