import React from 'react'
import { links, social } from '../data'
import { useTranslation } from 'react-i18next'

function LanguageBar() {
    const [t, i18n] = useTranslation('global');

    const changeLang = (lang: string) => {
        i18n.changeLanguage(lang);
    }
    return (
        <div className='lang_bar'>
            <ul className='language-icons'>
                <li><button onClick={() => changeLang("sr")}>SR</button></li>
                {/* <li><p>|</p></li> */}
                <li><button onClick={() => changeLang("en")}>EN</button></li>

                {/* {social.map((link) => {
                    const { id, url, text } = link;
                    return (
                        <li key={id}>
                            <a href={url}>{text}</a>
                        </li>
                    )
                })}
                <li><a href='#'><FaTwitter /></a></li> */}
            </ul>
        </div>
    )
}

export default LanguageBar