import React from 'react'
import Octapharma from '../../assets/Octapharma.svg'
import Galenika from '../../assets/galenika.svg'
import Hubner from '../../assets/hubner.svg'
import dr_pfleger from '../../assets/dr_pfleger.svg'
import alfa_intes from '../../assets/alfa_intes.svg'
import './partners.css'
import { useTranslation } from 'react-i18next'

function Partners() {
    const [t, i18n] = useTranslation('global');

    return (
        <>
            <div className='partners' id='our_partners'>
                <h1>{t("our_partners.title")}</h1>
                <div className='card'>
                    <img className='logo_comp' src={Octapharma} alt='Octapharma' />
                    <div className='desc'>
                        <h3>{t("our_partners.octapharma")}</h3>
                        <p><strong>{t("our_partners.octapharma")}</strong>{t("our_partners.text_octa")}
                        </p><br />
                        <a className='button' href='https://www.octapharma.com/serbia/' target='blank'>{t("our_partners.button_octa")}</a>
                    </div>
                </div>

                <div className='card'>
                    <div className='desc'>
                        <h3>{t("our_partners.galenica")}</h3>
                        <p>
                            <strong>{t("our_partners.galenica")}</strong> {t("our_partners.text_galenica")}
                        </p><br />
                        <a className='button' href='https://galenica.gr/en/' target='blank'>{t("our_partners.button_galenica")}</a>
                    </div>
                    <img className='logo_comp' src={Galenika} alt='Octapharma' />
                </div>

                <div className='card left'>
                    <img className='logo_comp' src={dr_pfleger} alt='dr_pfleger' />
                    <div className='desc'>
                        <h3>{t("our_partners.dr_pfleger")}</h3>
                        <p>
                            <strong>{t("our_partners.dr_pfleger")}</strong> {t("our_partners.text_dr_pfleger")}
                        </p><br />
                        <a className='button' href='https://dr-pfleger.de/' target='blank'>{t("our_partners.button_dr_pfleger")}</a>
                    </div>
                </div>

                <div className='card'>
                    <div className='desc'>
                        <h3>{t("our_partners.alfa_intes")}</h3>
                        <p>
                            <strong>{t("our_partners.alfa_intes")}</strong> {t("our_partners.text_alfa_intes")}
                        </p><br />
                        <a className='button' href='https://www.alfaintes.it/en' target='blank'>{t("our_partners.button_alfa_intes")}</a>
                    </div>
                    <img className='logo_comp' src={alfa_intes} alt='alfa_intes' />
                </div>

                <div className='card left'>
                    <img className='logo_comp' src={Hubner} alt='Octapharma' />
                    <div className='desc'>
                        <h3>{t("our_partners.hubner")}</h3>
                        <p>
                            <strong>{t("our_partners.hubner")}</strong> {t("our_partners.text_hubner")}
                        </p><br />
                        <a className='button' href='https://huebner-vital.com/en/' target='blank'>{t("our_partners.button_hubner")}</a>
                    </div>
                </div>


            </div>
        </>
    )
}

export default Partners