import React from 'react'
import './main.css'
import video from '../../assets/bgVideo.mp4'

function Main() {
    return (
        <div className='main'>
            <div className='overlay'></div>



            <video src={video} autoPlay="autoplay" playsInLine="playsinline" loop="true" muted="true">
                {/* <source src={video} type='video/mp4' /> */}
            </video>
            <div className='naslovi'>
                {/* <h2>Health. Safety. Trust.</h2> */}
            </div>
        </div>

    )
}

export default Main

