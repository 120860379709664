import React from 'react'
import './slika.css'
import slika from "../../assets/glavna.jpg"

function section1() {
    return (
        <div className='section1'>
            <img src={slika} />

        </div>
    )
}

export default section1